import { NavigationMenu as KNavigationMenu } from '@kobalte/core/navigation-menu';
import { Show } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { IconCaretDownMd } from '@troon/icons/caret-down-md';
import { Dynamic } from 'solid-js/web';
import { Link } from './link';
import type { IconComponent } from '@troon/icons';
import type { ComponentProps, JSXElement, ParentProps } from 'solid-js';

type Props = ComponentProps<typeof KNavigationMenu> & {
	trigger: string | (() => JSXElement);
	sameWidth?: boolean;
	showIcon?: boolean;
};

export function ResponsiveNavigationMenu(props: Props) {
	return (
		<>
			<KNavigationMenu class="hidden lg:block">
				<KNavigationMenu.Menu>
					<div class="flex flex-col gap-2">{props.children}</div>
				</KNavigationMenu.Menu>
			</KNavigationMenu>
			<NavigationMenu class="lg:hidden" {...props} />
		</>
	);
}

export function NavigationMenu(props: Props) {
	return (
		<KNavigationMenu placement={props.placement} class={props.class} delayDuration={0} skipDelayDuration={300}>
			<KNavigationMenu.Menu sameWidth={props.sameWidth === false ? false : true} fitViewport orientation="horizontal">
				{/* @ts-expect-error */}
				<NavigationMenuTrigger showIcon={props.showIcon}>{props.trigger}</NavigationMenuTrigger>
				<NavigationMenuPortal sameWidth={props.sameWidth}>{props.children}</NavigationMenuPortal>
			</KNavigationMenu.Menu>
			<KNavigationMenu.Viewport class="group/viewport z-50 animate-out fade-out slide-out-to-top-12 anim-duration-200 ui-expanded:animate-in ui-expanded:slide-in-from-top-12" />
		</KNavigationMenu>
	);
}

type TriggerProps = ParentProps<{ showIcon?: boolean }>;

export function NavigationMenuTrigger(props: TriggerProps) {
	return (
		<KNavigationMenu.Trigger
			class={twJoin(
				'group rounded outline-none transition-all duration-200',
				typeof props.children === 'string'
					? 'flex w-full items-center justify-between border border-neutral bg-white px-4 py-3 focus-visible:ring-1 focus-visible:ring-brand-700'
					: 'flex items-center gap-x-2',
			)}
		>
			{props.children}
			<Show when={props.showIcon !== false}>
				<KNavigationMenu.Icon class="transition-transform duration-200 ui-expanded:rotate-180">
					<IconCaretDownMd class="text-xl text-brand" />
				</KNavigationMenu.Icon>
			</Show>
		</KNavigationMenu.Trigger>
	);
}

type PortalProps = ParentProps<{ sameWidth?: boolean }>;

export function NavigationMenuPortal(props: PortalProps) {
	return (
		<KNavigationMenu.Portal>
			<KNavigationMenu.Content
				// eslint-disable-next-line tailwindcss/no-arbitrary-value
				class={twJoin(
					'group/portal relative top-2 flex max-h-[var(--kb-popper-content-available-height)] flex-col gap-2 overflow-auto rounded bg-white p-3 shadow-xl outline-none',
					props.sameWidth !== false ? 'w-[var(--kb-popper-anchor-width)]' : 'min-w-72',
				)}
			>
				{props.children}
			</KNavigationMenu.Content>
		</KNavigationMenu.Portal>
	);
}

type ItemProps = ComponentProps<typeof KNavigationMenu.Item> & {
	icon?: IconComponent;
};

export function NavigationMenuItem(props: ItemProps) {
	return (
		<KNavigationMenu.Item
			as={Link}
			href={props.href}
			class="group relative flex flex-row items-center gap-2 overflow-hidden rounded bg-white p-4 text-neutral-950 transition-colors duration-200 hover:bg-brand-100 focus-visible:ring-0 focus-visible:ring-offset-0 aria-current-page:bg-brand-100"
		>
			<span class="absolute inset-y-0 left-0 w-4 rounded border-s-0 border-s-white transition-all duration-200 group-hover:border-s-brand-100 group-hover:bg-brand-100 group-data-[expanded]/viewport:hidden group-aria-current-page:block group-aria-current-page:border-s-4 group-aria-current-page:border-s-brand group-aria-current-page:bg-brand-100 group-data-[expanded]/viewport:group-aria-current-page:hidden" />
			<Dynamic component={props.icon} class="shrink-0 grow-0 text-lg text-brand" />
			{props.children}
		</KNavigationMenu.Item>
	);
}
